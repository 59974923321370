/** @define backtop;  weak */
.backtop {
  position: fixed;
  right: 10px;
  bottom: 15px;
  z-index: 9 !important;
  /*@media only screen and (min-width: 590px) {
    bottom: 7px;
  }*/
  /*@media only screen and (min-width: 680px) {
    bottom: 80px;
  }

  @media only screen and (min-width: 1600px) {
    bottom: 7px;
  }*/
}
@media only screen and (min-width: 1530px) {
  .backtop {
    right: 50%;
    margin-right: -800px;
  }
}
.backtop__link {
  display: block;
  width: 40px;
  color: #000000;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transition: 1s;
}
@media only screen and (min-width: 1560px) {
  .backtop__link {
    width: 60px;
  }
}
.backtop__link:hover {
  text-decoration: none;
}
.backtop__link:hover .backtop__text {
  color: #e36c1b;
}
.backtop__picture {
  display: block;
  width: 40px;
  height: 40px;
  background: #1a81e0 url("../../../images/up-arrow.png") no-repeat center center;
  border-radius: 5px;
  transition: 1s;
}
.backtop__picture:hover {
  background-color: #e36c1b;
}
@media only screen and (min-width: 1560px) {
  .backtop__picture {
    width: 50px;
    height: 50px;
    margin: 0 auto 7px auto;
    border-radius: 10px;
  }
}
.backtop__text {
  display: none;
  font-weight: bold;
  font-family: ubuntubold, arial, Helvetica, sans-serif;
  color: #1a81e0;
  transition: all 1s ease-in-out;
}
.backtop__text:hover {
  color: #e36c1b;
}
@media only screen and (min-width: 1600px) {
  .backtop__text {
    display: inherit;
  }
}
/*# sourceMappingURL=css/scroll-top.css.map */